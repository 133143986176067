import { AccessibilityNewTwoTone } from "@mui/icons-material";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Badge } from "@mui/material";
import {
  AppBar,
  Avatar,
  Box,
  InputBase,
  Menu,
  MenuItem,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const StyledToolBar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const Search = styled("div")(({ theme }) => ({
  padding: "0 10px",
  borderRadius: theme.shape.borderRadius,
}));

const Icons = styled(Box)(({ theme }) => ({
  display: "none",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
  },
}));

const UserBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

const Navbar = () => {
  const [open, setOpen] = useState(false);

  return (
    <AppBar position="sticky">
      <StyledToolBar>
        <Typography
          variant="h6"
          sx={{
            display: { xs: "none", sm: "block" },
          }}
        >
          RUSYA
        </Typography>
        <AccessibilityNewTwoTone
          sx={{
            display: { sm: "none" },
          }}
        />
        <Search
          maxWidth="md"
          sx={{
            backgroundColor: "white",
          }}
        >
          <InputBase
            placeholder="Search..."
            sx={{ width: "600px", color: "black" }}
          />
        </Search>
        <Icons>
          <Badge
            badgeContent={4}
            color="error"
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <MailIcon />
          </Badge>
          <Badge
            badgeContent={2}
            color="error"
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <NotificationsIcon />
          </Badge>
          <Avatar
            src="https://www.w3schools.com/w3images/avatar2.png"
            sx={{
              width: "40px",
              height: "40px",
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={(e) => setOpen(true)}
          />
        </Icons>
        <UserBox
          onClick={(e) => setOpen(true)}
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <Badge badgeContent={6} color="error">
            <Avatar
              src="https://www.w3schools.com/w3images/avatar2.png"
              sx={{ width: "40px", height: "40px" }}
            />
          </Badge>
          <Typography variant="span">John</Typography>
        </UserBox>
      </StyledToolBar>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        open={open}
        onClose={(e) => setOpen(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem>Profile</MenuItem>
        <MenuItem>My account</MenuItem>
        <MenuItem>Logout</MenuItem>
      </Menu>
    </AppBar>
  );
};

export default Navbar;
